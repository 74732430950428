import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"

function Loading() {
    const { height, totalWidth, loading } = useState(hive.state)

    return <div style={{
        position: "absolute",
        height: height.get() * 0.25 + "px",
        width: height.get() * 0.25 + "px",
        left: `calc(50vw - ${height.get() * 0.15 * 0.5 + "px"})`,
        marginTop: (height.get() * 0.5) - (height.get() * 0.15) + "px",
        backgroundImage: `url(/gui/loading.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center",
        zIndex: 5,
        pointerEvents: "none",
        opacity: loading.get() ? 1 : "0",
        transition: "opacity 0.5s"
    }}>

    </div>
}

export default Loading