import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import { R_MOBILE_HEADER_HEIGHT } from './constant';

function MobileLogo() {
    const { width, height } = useState(hive.state)

    return <div style={{
        position: "absolute",
        width: width.get() * 0.20,
        height: height.get() * R_MOBILE_HEADER_HEIGHT,
        zIndex: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }}>
        <div style={{
            margin: "auto",
            width: "70%",
            height: "60%",
            backgroundImage: "url(/bati_logo.png)",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        }}>
        </div>
    </div>
}


export default MobileLogo