import React, { useEffect } from 'react';
import hive from "../hive"
import { ThreeDRotation } from '@material-ui/icons';
import { useState } from "@hookstate/core"

function RotateIcon() {
    const { height, showRotate, mainColor } = useState(hive.state)

    return <div style={{
        position: "absolute",
        height: height.get() * 0.25 + "px",
        width: height.get() * 0.25 + "px",
        left: `calc(50vw - ${height.get() * 0.15 * 0.5 + "px"})`,
        marginTop: (height.get() * 0.5) - (height.get() * 0.15) + "px",
        zIndex: 5,
        pointerEvents: "none",
        opacity: showRotate.get() ? 0.7 : "0",
        transition: "opacity 0.5s",
        color: `#${mainColor.get()}`,
    }}>
        <ThreeDRotation style={{
            height: height.get() * 0.25 + "px",
            width: height.get() * 0.25 + "px",
        }}></ThreeDRotation>
    </div>
}

export default RotateIcon;