import React, { useEffect } from 'react';
import { createState, useState } from "@hookstate/core"
import { id } from "../../../project.json"

declare const PouchDB: any;
const state = createState({
    ready: false,
    isLoggued: false,
    passwordInput: "",
    wrongPassword: false,
    data: null,
})

// const db = new PouchDB(`https://build:build45@${id}.hive-project.fr/_db/${id}`, {
const db = new PouchDB(`https://${id}.hive-project.fr/_db/${id}`, {
    fetch: (url: any, opts: any) => {
        opts.credentials = 'include';
        return PouchDB.fetch(url, opts);
    },
    skip_setup: true
});

function Admin() {
    const { isLoggued, ready, wrongPassword, passwordInput, data } = useState(state)

    const getData = async () => {
        const res = await db.get("lots")
        data.set(Object.entries(res.data as any) as any)
        console.log(res);
    }

    useEffect(() => {
        ; (async () => {
            const res = await db.getSession()
            isLoggued.set(res.userCtx.name ? true : false)
            getData()
            ready.set(true)
            console.log(res);
        })()
        return () => { }
    }, [])

    return <div>
        {ready.get() && <>
            {!isLoggued.get() && <div>
                <form onSubmit={async (e) => {
                    e.preventDefault()
                    try {
                        const res = await db.logIn(id, passwordInput.get())
                        isLoggued.set(true)
                    } catch (error) {
                        wrongPassword.set(true)
                    }
                }}>
                    <input value={passwordInput.get()} onChange={(e) => passwordInput.set(e.target.value)} placeholder="Mot de passe"></input>
                    <input type="submit" value="Se connecter"></input>
                    {wrongPassword.get() && <>
                        <div style={{ color: "red" }}>
                            Mauvais Mot de passe
                    </div>
                    </>
                    }
                </form>
            </div>}
            {isLoggued.get() && <>
                {data.get() && <>
                    <button style={{
                        padding: "10px"
                    }} onClick={async () => {
                        const d = JSON.parse(JSON.stringify(data.get()))
                        const final: any = {}
                        for (let item of d) {
                            final[item[0]] = item[1]
                        }
                        const res = await db.get("lots")
                        res.data = final;
                        await db.put(res)
                        window.location.href = `https://${id}.hive-project.fr`
                    }}>
                        Valider les changements
                    </button>
                    <div>
                        <table style={{
                            border: "1px solid grey",
                        }}>
                            <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        Surface
                                    </th>
                                    <th>
                                        Pieces
                                    </th>
                                    <th>
                                        Prix
                                    </th>
                                    <th>
                                        Disponible
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(data as any).map((lot: any, i: number) => <tr key={i} style={{
                                    // display: "flex",
                                }}>
                                    <td>
                                        {lot[1].id.get()}
                                    </td>
                                    <td>
                                        <input type="number" value={lot[1].surface.get()} onChange={e => { lot[1].surface.set(e.target.value) }}></input>
                                    </td>
                                    <td>
                                        <input type="number" value={lot[1].type.get()} onChange={e => {
                                            if (e.target.value === "" || (parseInt(e.target.value) < 7 && parseInt(e.target.value) > 0)) {
                                                console.log(lot[1].get());
                                                lot[1].type.set(e.target.value)
                                            }
                                        }}></input>
                                    </td>
                                    <td>
                                        <input type="number" value={lot[1].price.get()} onChange={e => { lot[1].price.set(e.target.value) }}></input>
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={lot[1].active.get() ? true : false} onChange={e => {
                                            console.log(e.target.checked);
                                            lot[1].active.set(e.target.checked)
                                        }}></input>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </>}
            </>}
        </>}
    </div>
}

export default Admin;