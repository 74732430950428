import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import LotCard from './LotCard';
import HiveSlider from './HiveSlider';
import { project } from "./project/project"
import TypeFilter from "./TypeFilter"

function Search() {
    const { height, mainColor, searchResult, totalWidth, isSurfaceAllFilter } = useState(hive.state)
    const searchFilter = useState(hive.searchFilter)
    const additionelSearchFilter = useState(hive.additonalSearchFilter)

    return <div style={{
        position: "absolute",
        height: height.get() + "px",
        width: "100vw",
        background: `#${mainColor.get()}BB`,
        zIndex: 1,
        color: "white",
    }}>
        <div style={{
            height: height.get() * 0.5 + "px"
        }}>
            <div style={{
                height: height.get() * 0.05,
            }}>
            </div>

            <div style={{
                width: totalWidth.get() * 0.5 + "px",
                margin: "auto",
            }}>
                <div style={{
                    // height: height.get() * 0.1,
                    // lineHeight: height.get() * 0.1 + "px",
                    fontSize: height.get() * 0.028 + "px",
                    display: "flex",
                }}>
                    <div style={{
                        height: height.get() * 0.05,
                        lineHeight: height.get() * 0.05 + "px",
                        width: totalWidth.get() * 0.1,

                    }}>
                        Typologies
                    </div>
                    <TypeFilter></TypeFilter>
                </div>

                <div style={{
                    height: height.get() * 0.05,
                }}>
                </div>

                {/* PRICE */}
                {hive.priceSlider.domain[1].get() > 5000 && <>
                    <div style={{
                        display: "flex",
                    }}>
                        <div style={{
                            height: height.get() * 0.05,
                            lineHeight: height.get() * 0.05 + "px",
                            marginTop: height.get() * 0.005,
                            minWidth: totalWidth.get() * 0.11,
                            fontSize: height.get() * 0.028 + "px",
                        }}>
                            Prix
            </div>
                        <div style={{
                            width: totalWidth.get() * 0.4 + "px",
                            marginLeft: totalWidth.get() * 0.02,
                        }}>
                            <HiveSlider sliderState={hive.priceSlider}></HiveSlider>
                        </div>
                    </div>

                </>}

                <div style={{
                    height: height.get() * 0.05,
                }}>
                </div>
                <div style={{
                    display: "flex",
                }}>
                    <div style={{
                        height: height.get() * 0.05,
                        lineHeight: height.get() * 0.05 + "px",
                        marginTop: height.get() * 0.005,
                        fontSize: height.get() * 0.028 + "px",
                        minWidth: totalWidth.get() * 0.11,
                    }}>
                        Surface
            </div>
                    <div style={{
                        width: totalWidth.get() * 0.4 + "px",
                        marginLeft: totalWidth.get() * 0.02,
                    }}>
                        <HiveSlider sliderState={hive.surfaceSlider}></HiveSlider>
                    </div>
                </div>

                {isSurfaceAllFilter.get() && <>
                    <div style={{
                        display: "flex",
                    }}>
                        <div style={{
                            height: height.get() * 0.05,
                            lineHeight: height.get() * 0.05 + "px",
                            marginTop: height.get() * 0.005,
                            fontSize: height.get() * 0.028 + "px",
                            minWidth: totalWidth.get() * 0.11,
                        }}>
                            Surface Parcelle
                        </div>
                        <div style={{
                            width: totalWidth.get() * 0.4 + "px",
                            marginLeft: totalWidth.get() * 0.02,
                        }}>
                            <HiveSlider sliderState={hive.surfaceAllSlider}></HiveSlider>
                        </div>
                    </div>
                </>}
            </div>

        </div>
        <div style={{
            height: height.get() * 0.5 + "px",
            background: `#${mainColor.get()}`,
            opacity: "0.9",
        }}>
            <div style={{
                textAlign: "center",
                color: "white",
                fontSize: height.get() * 0.02 + "px",
                height: height.get() * 0.15,
                lineHeight: height.get() * 0.15 + "px",
            }}>
                RESULTAT DE RECHERCHE
        </div>
            <div style={{
                display: "flex",
                width: "100vw",
                overflowX: "scroll",
                height: height.get() * 0.35,
            }}>
                {searchResult.filter(lot => !lot.get().hide_lot).map((lot, i) =>
                    <div key={i} style={{
                        marginLeft: totalWidth.get() * 0.01 + "px",
                        marginRight: totalWidth.get() * 0.01 + "px"
                    }}>
                        <LotCard lot={lot} width={0.3} height={0.25}></LotCard>
                    </div>)}
            </div>
        </div>

    </div >
}


export default Search