import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"


function Compas() {
    const { height, compasStart, mainColor } = useState(hive.state)
    const angle = useState(hive.angle)
  
    const a = {
      fill: "#fff",
      opacity: 0.692
    }
  
    const b = {
      fill: `#${mainColor.get()}`
    }
  
    const c = {
      fill: `#${mainColor.get()}`,
      transformBox: "fill-box" as any,
      transformOrigin: `50% 70%`,
    }
  
    return <div style={{
      position: "absolute",
      left: `calc(100vw - ${height.get() * 0.13 + "px"})`,
      transform: `rotate(${(-angle.get() + compasStart.get())}deg)`,
      marginTop: height.get() * 0.03 + "px",
      zIndex: 2,
      width: height.get() * 0.1 + "px",
      height: height.get() * 0.1 + "px"
    }}>
      <svg
        xmlns="http://purl.org/dc/elements/1.1/"
        id="svg64"
        version="1.1"
        viewBox="0 0 79 77"
        height={height.get() * 0.1}
        width={height.get() * 0.1}>
        <g
          id="g62"
          transform="translate(-1784 -97)">
  
          <ellipse style={a} cx="39.5" cy="38.5" rx="39.5" ry="38.5" transform="translate(1784 97)" />
  
          {/* Contour */}
          <path
            style={b}
            d="M 1823.547,172.094 A 36.547,36.547 0 1 0 1787,135.547 a 36.59,36.59 0 0 0 36.547,36.547 z m 0,-65.341 a 28.795,28.795 0 1 1 -28.795,28.794 28.822,28.822 0 0 1 28.795,-28.795 z"
            id="path46" />
  
          {/* Point center */}
          <path
            style={b}
            d="m 1823.547,136.932 a 1.386,1.386 0 0 0 1.384,-1.384 1.371,1.371 0 0 0 -0.406,-0.977 1.348,1.348 0 0 0 -0.978,-0.408 1.3845,1.3845 0 0 0 0,2.769 z"
            id="path48" />
  
          {/* Compass */}
          <path
            style={c}
            d="m 1818.3933,135.30116 c -0.027,0.13586 -0.023,0.27607 0.011,0.41028 v 0.0122 c 10e-5,0.0122 0.011,0.0303 0.011,0.0425 l 4.2961,7.82944 c 0.3128,0.49905 1.4093,0.51051 1.7229,0.002 l 4.318,-7.93742 c 0.011,-0.006 0.011,-0.0184 0.011,-0.0305 0.012,-0.0812 0.012,-0.16381 -7e-4,-0.24497 -0.011,-0.0425 0.011,-0.0797 -6e-4,-0.12276 l -0.011,-0.006 -4.4214,-17.74359 c -0.2276,-0.87337 -1.4714,-0.86244 -1.6837,0.0148 z m 7.1502,-1.81915 c 0.5375,0.52557 0.8394,1.24631 0.8369,1.99805 0.01,3.07904 -4.2231,3.92885 -5.4113,1.09226 -1.1881,-2.83659 2.3838,-5.25405 4.5744,-3.09031 z"
            id="path50" />
  
          <path
            style={b}
            d="m 1821.941,111.581 3.145,4.042 h 1.008 v -5.537 h -0.93 v 4.142 l -3.223,-4.142 h -0.93 v 5.537 h 0.93 z"
            id="path52" />
  
          <path
            style={b}
            d="m 1824.1,156.733 c -1.019,-0.255 -1.772,-0.421 -1.772,-1.163 0,-0.631 0.576,-0.864 1.207,-0.864 a 3.138,3.138 0 0 1 1.927,0.653 l 0.642,-0.908 a 4.032,4.032 0 0 0 -2.526,-0.863 c -1.318,0 -2.514,0.664 -2.514,2.071 0,1.517 1.107,1.883 2.558,2.237 0.731,0.166 1.44,0.365 1.44,1.063 0,0.642 -0.631,0.919 -1.274,0.919 a 3.438,3.438 0 0 1 -2.248,-0.941 l -0.764,0.908 a 4.4,4.4 0 0 0 2.981,1.153 c 1.406,0 2.558,-0.742 2.558,-2.1 0,-1.358 -0.897,-1.844 -2.215,-2.165 z"
            id="path54" />
  
          <path
            style={b}
            d="m 1804.144,138.315 1.927,-5.537 h -1.007 l -1.4,3.987 -1.226,-3.987 h -0.842 l -1.24,3.987 -1.384,-3.987 h -1.008 l 1.927,5.537 h 0.93 l 1.163,-3.8 h 0.066 l 1.163,3.8 z"
            id="path56" />
  
          <path
            style={b}
            d="m 1849.141,137.806 h -3.654 v -1.772 h 3.181 v -1 h -3.181 v -1.758 h 3.544 v -1.052 h -4.663 v 6.645 h 4.773 z"
            id="path58" />
        </g>
      </svg>
    </div>
  }
  

  export default Compas