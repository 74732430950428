export const fragmentShaderSource = `

precision highp float;

// our texture
// uniform sampler2D u_image;
uniform sampler2D u_image;
uniform sampler2D u_mask;
uniform sampler2D u_map;
uniform vec4 u_wires[62];
uniform int u_wires_size;
uniform int u_width;
uniform int u_height;
uniform int u_wireOffset;
uniform int u_isMap;


// uniform float poisBright[20];

// the texCoords passed in from the vertex shader.
varying vec2 v_texCoord;

#define COTE 0.002
void main() {

//    vec4 image = texture2D(u_image, v_texCoord);
  vec4 mask = texture2D(u_mask, v_texCoord);
  vec4 image = texture2D(u_image, v_texCoord);
  vec4 map = texture2D(u_map, v_texCoord);


  if (mask != vec4(1.,1.,1.,1)) {
    for (int i=0; i <= 61; i++) {
      if (i >= u_wires_size) {
        break;
      }
      
      float color = 0.;
      if (u_wireOffset == 0) color = mask[2];
      if (u_wireOffset == 1) color = mask[1];
      if (u_wireOffset == 2) color = mask[0];

      if (int( floor(color * 256.00000) ) == i) {
          float alpha = u_wires[i][3];
          // gl_FragColor = vec4(float(i)/10.,0.,0.,1.);
          gl_FragColor = mix(image, u_wires[i], alpha);
          return;
      }
    }
  } 
  gl_FragColor = image;
}

`