import hive from "./index"
import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { Slider, Handles, Tracks, Rail } from 'react-compound-slider'

function HandleCursor() {
    const { mainColor } = useState(hive.state)

    return <svg
        xmlns="http://purl.org/dc/elements/1.1/"
        id="svg8"
        version="1.1"
        viewBox="0 0 210 297"
        height="100%"
        width="100%">
        <defs
            id="defs2" />
        <g
            id="layer1">
            <path
                id="path22"
                d="m 100.91964,14.1741 h 76.91816 c 0,0 10.9613,-1.700886 10.9613,10.961316 0,12.662202 0,92.037204 0,92.037204 0,0 0.18899,13.79613 -11.15029,23.43452 -11.33929,9.63839 -75.40625,49.70387 -75.40625,49.70387 L 24.568452,138.1503 c 0,0 -5.858631,-2.64584 -5.858631,-18.52084 0,-15.875 0,-96.005948 0,-96.005948 0,0 -1.133928,-9.449412 9.260418,-9.449412 10.394344,0 72.193451,0 72.193451,0 z"
                style={{
                    fill: `#${mainColor.get()}`,
                    stroke: `#000000`,
                    strokeWidth: `0.264583px`,
                    strokeLinecap: `butt`,
                }}
            />
        </g>
    </svg >

}

function numberWithSpaces(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Handle({
    handle: { id, value, percent },
    getHandleProps,
    sliderState
}: any) {
    const { height, totalWidth } = useState(hive.state)
    const widthRatio = useState(sliderState).nested("widthRatio")
    const heightRatio = useState(sliderState).nested("heightRatio")
    const text = useState(sliderState).nested("text")

    const getValue = () => {
        return numberWithSpaces(value)
    }

    return (
        <div
            style={{
                left: `${percent}%`,
                position: 'absolute',
                marginLeft: -height.get() * heightRatio.get() * 0.1,
                // marginTop: height.get() * heightRatio.get() * 0.1,
                zIndex: 2,
                width: height.get() * heightRatio.get() * 0.3,
                height: height.get() * heightRatio.get() * 0.3,
                // border: 0,
                // textAlign: 'center',
                cursor: 'pointer',
                // // borderRadius: '50%',
                // backgroundColor: '#2C4870',
                // color: '#333',
            }}
            {...getHandleProps(id)}
        >
            <div style={{
                position: "absolute",
                marginTop: height.get() * heightRatio.get() * 0.15,
            }}>
                <HandleCursor></HandleCursor>
            </div>
            <div style={{
                position: "absolute",
                marginTop: height.get() * heightRatio.get() * 0.4,
                fontSize: height.get() * heightRatio.get() * 0.25,
                fontFamily: "MuktaRegular",
                textAlign: "center",
                color: "white",
                userSelect: "none",
                width: totalWidth.get() * 0.1,
            }}>
                {`${getValue()}${text.get()}`}
            </div>
        </div>
    )
}

function Track({ source, target, getTrackProps, sliderState }: any) {
    const { height, totalWidth } = useState(hive.state)
    const widthRatio = useState(sliderState).nested("widthRatio")
    const heightRatio = useState(sliderState).nested("heightRatio")

    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 1,
                height: height.get() * heightRatio.get() * 0.09,
                marginTop: (height.get() * heightRatio.get() * 0.3) - (height.get() * heightRatio.get() * 0.09 * 0.5),
                backgroundColor: '#546C91',
                borderRadius: 5,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */}
        />
    )
}


function HiveSlider({ sliderState }: any) {
    const { height, totalWidth } = useState(hive.state)

    const values = useState(sliderState).nested("values")
    const domain = useState(sliderState).nested("domain")
    const widthRatio = useState(sliderState).nested("widthRatio")
    const heightRatio = useState(sliderState).nested("heightRatio")
    const step = useState(sliderState).nested("step")


    const sliderStyle = {  // Give the slider some width
        position: 'relative',
        width: totalWidth.get() * widthRatio.get(),
        height: height.get() * heightRatio.get(),
        // border: `1px solid red`,
    }

    const railStyle = {
        position: 'absolute',
        width: totalWidth.get() * widthRatio.get(),
        height: height.get() * heightRatio.get() * 0.09,
        marginTop: (height.get() * heightRatio.get() * 0.3) - (height.get() * heightRatio.get() * 0.09 * 0.5),
        // borderRadius: 5,
        backgroundColor: '#8B9CB6',
        cursor: "pointer"
    }

    const lastValue = useState([values.get()[0], values.get()[1]])

    return <Slider
        onUpdate={(e) => {
            if (e[0] !== lastValue.get()[0] || e[1] !== lastValue.get()[1]) {
                lastValue.set([e[0], e[1]])
                values.set([e[0], e[1]])
                hive.applyFilter()
            }
            // values.set([e[0], e[1]])
        }}
        rootStyle={sliderStyle as any /* inline styles for the outer div. Can also use className prop. */}
        domain={domain.get()}
        values={values.get()}
        mode={2}
        step={step.get()}
    >
        <Rail>
            {({ getRailProps }) => (
                <div style={railStyle as any} {...getRailProps()} />
            )}
        </Rail>
        <Handles>
            {({ handles, getHandleProps }: any) => (
                <div className="slider-handles">
                    {handles.map((handle: any) => (
                        <Handle
                            key={handle.id}
                            handle={handle}
                            getHandleProps={getHandleProps}
                            sliderState={sliderState}
                        />
                    ))}
                </div>
            )}
        </Handles>
        <Tracks>
            {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                    {tracks.filter((e, i) => i == 1).map(({ id, source, target }, i) => (
                        <Track
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                            sliderState={sliderState}
                        />
                    ))}
                </div>
            )}
        </Tracks>
    </Slider>
}

export default HiveSlider
