import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { project } from "./project/project"

function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


function InfoCard() {
  const { infoCard: lot, totalWidth, height, mainColor, favoriteList } = useState(hive.state)
  const background = useState([255, 255, 255, 255])

  useEffect(() => {
    // console.log(lot.get());
    const v = hive.getSearchFilterFromLot(lot.get())
    background.set(v.background)
  }, [])

  return <div style={{
    position: "absolute",
    width: "20vw",
    left: "80vw",
    height: height.get(),
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    pointerEvents: "none",
  }}> {lot.get() &&

    <div style={{
      width: "100%",
      // height: height.get() * 0.3 + "px",
      background: "#f1f1f1",
      boxShadow: `-${height.get() * 0.005}px ${height.get() * 0.005}px ${height.get() * 0.01}px 0px rgba(0,0,0,0.15)`,
      display: "flex",
      justifyContent: "center",
      pointerEvents: "initial",
    }}>
      <div style={{
        left: -height.get() * 0.045 * 0.5 + "px",
        marginTop: -height.get() * 0.045 * 0.5 + "px",
        position: "absolute",
        width: height.get() * 0.045 + "px",
        height: height.get() * 0.045 + "px",
        borderRadius: height.get() * 0.045 * 0.5 + "px",
        backgroundColor: "#3b99fc",
        backgroundImage: "url(/gui/i.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "40%",
      }}>
      </div>
      <div style={{
        width: "80%",
      }}>
        <div style={{
          // fontFamily: 'MuktaBold',
          fontSize: height.get() * 0.03 + "px",
          color: "#9f9f9f",
          height: height.get() * 0.07 + "px",
          lineHeight: height.get() * 0.07 + "px",
          borderBottom: "1px solid #d8d8d8",
          display: "flex",
          justifyContent: "space-between",
        }}>
          <div>
            Lot : {lot.get().id}
          </div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <div style={{
              background: "white",
              border: `0.5px solid #${mainColor.get()}`,
              height: height.get() * 0.04 + "px",
              lineHeight: height.get() * 0.04 + "px",
              width: height.get() * 0.04 + "px",
              borderRadius: height.get() * 0.04 * 0.5 + "px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}>
              <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                color: "#ff0057",
              }}>
                <div
                  onClick={() => {
                    hive.toggleFavorite(lot.get().id)
                  }
                  }
                  style={{
                    height: height.get() * 0.03 + "px"
                  }}>
                  {!favoriteList.find((e) => e.get() === lot.get().id) &&
                    <FavoriteBorder style={{
                      width: height.get() * 0.025 + "px",
                      height: height.get() * 0.025 + "px",
                    }}></FavoriteBorder>
                  }
                  {favoriteList.find((e) => e.get() === lot.get().id) &&
                    <Favorite style={{
                      width: height.get() * 0.025 + "px",
                      height: height.get() * 0.025 + "px",
                    }}></Favorite>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{
          // fontFamily: 'MuktaBold',
          fontSize: height.get() * 0.02 + "px",
          color: "#9f9f9f",
          height: height.get() * 0.07 + "px",
          lineHeight: height.get() * 0.07 + "px",
          borderBottom: "1px solid #d8d8d8",
          display: "flex",
          justifyContent: "space-between"
        }}>
          <div style={{
            width: "40%",
            display: "flex",
          }}>
            <div>
              Type
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
              <div style={{
                height: height.get() * 0.035 + "px",
                width: height.get() * 0.035 + "px",
                lineHeight: height.get() * 0.035 + "px",
                borderRadius: height.get() * 0.035 * 0.5 + "px",
                textAlign: "center",
                background: `rgb(${background.get()[0]},${background.get()[1]},${background.get()[2]})`,
                marginLeft: height.get() * 0.035 * 0.5,
                color: "white",
              }}>
                T{lot.get().type}
              </div>
            </div>
          </div>
          <div style={{
            width: "50%",
          }}>
            Surf : {lot.get().surface} m2
          </div>
          <div style={{
            width: "10%"
          }}>
          </div>
        </div>

        <div style={{
          fontFamily: 'MuktaBold',
          fontSize: height.get() * 0.025 + "px",
          color: `#${mainColor.get()}`,
          height: height.get() * 0.07 + "px",
          lineHeight: height.get() * 0.07 + "px",
          // borderBottom: "1px solid #d8d8d8",
        }}>
          {lot.get().price && <>
            <span style={{
              color: "#9f9f9f",
            }}>Prix : </span>{numberWithSpaces(lot.get().price)}€
          </>
          }
        </div>

        {lot.get().price_condition && <>
          <div style={{
            fontFamily: 'MuktaBold',
            fontSize: height.get() * 0.025 + "px",
            color: `#${mainColor.get()}`,
            height: height.get() * 0.07 + "px",
            lineHeight: height.get() * 0.04 + "px",
            // borderBottom: "1px solid #d8d8d8",
          }}>
            <span style={{
              color: "#9f9f9f",
            }}>{project.price_condition.text} : </span>  <br /> {numberWithSpaces(lot.get().price_condition)}€
          </div>
          <div style={{
            fontSize: height.get() * 0.014 + "px",
            marginBottom : height.get() * 0.017 + "px",
          }}>
            {project.price_condition.description}
          </div>

        </>}


      </div>
    </div>
    }
  </div>
}

export default InfoCard