import React, { useEffect } from 'react';
import { KeyboardBackspace } from '@material-ui/icons';
import hive from "./index"
import { useState } from "@hookstate/core"

function GoBack() {
    const { mainColor, height, backColor } = useState(hive.state)
    const isOver = useState(false)


    return <div
        onClick={() => hive.goBack()}
        onMouseEnter={() => { isOver.set(true) }}
        onMouseLeave={() => { isOver.set(false) }}
        style={{
            position: "absolute",
            zIndex: 4,
            cursor: "pointer",
            // color: `#${mainColor.get()}`,
            display: "flex",
            justifyContent: "center",
            fontSize: height.get() * 0.03 + "px",
            width: height.get() * 0.25 + 'px',
            marginTop: height.get() * 0.015 + "px",
            userSelect: "none",
            color : `#${backColor.get()}`
        }}>
        <div style={{
            marginRight: isOver.get() ? height.get() * 0.007 : "0px",
            transition: "margin 0.3s",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <KeyboardBackspace style={{
                width: height.get() * 0.03 + "px",
                height: height.get() * 0.03 + "px"
            }}></KeyboardBackspace>
        </div>
        <div style={{
            marginLeft: isOver.get() ? height.get() * 0.007 : "0px",
            transition: "margin 0.3s",
        }}>
            RETOUR
      </div>
    </div>
}

export default GoBack