import { fragmentShaderSource } from "./fragment"
import { vertexShaderSource } from "./vertex"

const MAX_ARRAY_TEXTURE_CACHE = 120
export default class {
    glCanvas: HTMLCanvasElement;
    ctx: WebGLRenderingContext;
    colors: number[][] = [];
    lastPicDrawed?: {
        width: number,
        height: number,
        image: HTMLImageElement
    }
    wireOffset = 0
    lastWireDrawed?: {
        width: number,
        height: number,
        image: HTMLImageElement
    }

    // GL
    program: any
    gl!: {
        lastTexturePainted: WebGLTexture | undefined
        positionLocation: number
        texcoordLocation: number
        imageTexture: WebGLTexture
        wireTexture: WebGLTexture
        mapTexture: WebGLTexture
        resolutionLocation: WebGLUniformLocation
        colors: WebGLUniformLocation
        colors_size: WebGLUniformLocation
        width: WebGLUniformLocation
        height: WebGLUniformLocation
        wireOffset: WebGLUniformLocation
        isMap: WebGLUniformLocation

        texturePicCache: WebGLTexture[]
        textureWireCache: WebGLTexture[]
        textureMapCache: WebGLTexture[]

        imageTexurePicCache: (HTMLImageElement | null)[]
        imageTexureWireCache: (HTMLImageElement | null)[]
        imageTexureMapCache: (HTMLImageElement | null)[]

        lastImageWirePainted?: HTMLImageElement
        lastImageMapPainted?: HTMLImageElement
    }
    constructor(glCanvas: HTMLCanvasElement
    ) {
        this.glCanvas = glCanvas;
        // this.picCtx = this.picCanvas.getContext("2d") as CanvasRenderingContext2D;
        this.ctx = this.glCanvas.getContext("webgl", {
            alpha: false,
            // premultipliedAlpha: false,
        }) as WebGLRenderingContext;

        this.gl = {} as any;
        this.gl.texturePicCache = []
        this.gl.textureWireCache = []
        this.gl.textureMapCache = []
        this.initWebGl()
    }

    public setColors(colors: number[][] = []) {
        if (colors.length === 0) {
            colors = [[0, 0, 0, 0]]
        }
        this.colors = colors;
        this.ctx.uniform4fv(this.gl.colors, new Float32Array(colors.flat()));
        this.ctx.uniform1i(this.gl.colors_size, colors.length)
        this.ctx.drawArrays(this.ctx.TRIANGLES, 0, 6);
    }

    public updateGlSize() {
        // set the resolution
        const setRectangle = (gl: any, x: number, y: number, width: number, height: number) => {
            var x1 = x;
            var x2 = x + width;
            var y1 = y;
            var y2 = y + height;
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
                x1, y1,
                x2, y1,
                x1, y2,
                x1, y2,
                x2, y1,
                x2, y2,
            ]), gl.STATIC_DRAW);
        }
        // Turn on the position attribute
        this.ctx.enableVertexAttribArray(this.gl.positionLocation);
        const positionBuffer = this.ctx.createBuffer();
        this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, positionBuffer);
        // setRectangle(this.ctx, 0, 0, this.activeImage.width, this.activeImage.height);
        setRectangle(this.ctx, 0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        // Bind the position buffer.
        this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, positionBuffer);
        // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
        this.ctx.vertexAttribPointer(
            this.gl.positionLocation, 2, this.ctx.FLOAT, false, 0, 0);

        this.ctx.viewport(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        this.ctx.uniform2f(this.gl.resolutionLocation, this.ctx.canvas.width, this.ctx.canvas.height);


        // PAINT
        // if (this.lastPicDrawed) {
        //     this.paintPic(this.lastPicDrawed.image, this.lastPicDrawed.width, this.lastPicDrawed.height)
        // }
        // if (this.lastWireDrawed) {
        //     this.paintWire(this.lastWireDrawed.image, this.lastWireDrawed.width, this.lastWireDrawed.height)
        // }
    }

    public resetImageCache() {
        this.gl.imageTexurePicCache = Array.from({ length: MAX_ARRAY_TEXTURE_CACHE }).map(e => null)
        this.gl.imageTexureWireCache = Array.from({ length: MAX_ARRAY_TEXTURE_CACHE }).map(e => null)
        this.gl.imageTexureMapCache = Array.from({ length: MAX_ARRAY_TEXTURE_CACHE }).map(e => null)
    }

    initWebGl() {
        this.program = this.ctx.createProgram();
        const createShader = (gl: any, sourceCode: string, type: any) => {
            // Compiles either a shader of type gl.VERTEX_SHADER or gl.FRAGMENT_SHADER
            const shader = gl.createShader(type);
            gl.shaderSource(shader, sourceCode);
            gl.compileShader(shader);

            if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
                const info = gl.getShaderInfoLog(shader);
                throw 'Could not compile WebGL program. \n\n' + info;
            }
            return shader;
        }

        const vertexShader = createShader(this.ctx, vertexShaderSource, this.ctx.VERTEX_SHADER)
        const fragmentShader = createShader(this.ctx, fragmentShaderSource, this.ctx.FRAGMENT_SHADER)
        this.program = this.ctx.createProgram();

        this.ctx.attachShader(this.program, vertexShader);
        this.ctx.attachShader(this.program, fragmentShader);
        this.ctx.linkProgram(this.program);
        this.ctx.useProgram(this.program);

        this.gl.positionLocation = this.ctx.getAttribLocation(this.program, "a_position");
        this.gl.texcoordLocation = this.ctx.getAttribLocation(this.program, "a_texCoord");

        const wireSizeMode = this.ctx.NEAREST
        const picSizeMode = this.ctx.LINEAR
        const mapSizeMode = this.ctx.LINEAR


        // pic
        this.gl.imageTexture = this.ctx.createTexture() as WebGLTexture;
        const u_imageLoc = this.ctx.getUniformLocation(
            this.program, "u_image");
        this.ctx.uniform1i(u_imageLoc, 0);
        this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.imageTexture);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_S, this.ctx.CLAMP_TO_EDGE);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_T, this.ctx.CLAMP_TO_EDGE);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MIN_FILTER, picSizeMode);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MAG_FILTER, picSizeMode);


        // Wire
        this.gl.wireTexture = this.ctx.createTexture() as WebGLTexture;
        const u_maskloc = this.ctx.getUniformLocation(
            this.program, "u_mask");
        this.ctx.uniform1i(u_maskloc, 1);
        this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.wireTexture);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_S, this.ctx.CLAMP_TO_EDGE);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_T, this.ctx.CLAMP_TO_EDGE);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MIN_FILTER, wireSizeMode);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MAG_FILTER, wireSizeMode);

        // Map
        this.gl.mapTexture = this.ctx.createTexture() as WebGLTexture;
        const u_maploc = this.ctx.getUniformLocation(
            this.program, "u_map");
        this.ctx.uniform1i(u_maploc, 2);
        this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.mapTexture);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_S, this.ctx.CLAMP_TO_EDGE);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_T, this.ctx.CLAMP_TO_EDGE);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MIN_FILTER, mapSizeMode);
        this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MAG_FILTER, mapSizeMode);


        this.gl.texturePicCache = Array.from({ length: MAX_ARRAY_TEXTURE_CACHE }).map(e => {
            const text = this.ctx.createTexture() as WebGLTexture
            this.ctx.bindTexture(this.ctx.TEXTURE_2D, text);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_S, this.ctx.CLAMP_TO_EDGE);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_T, this.ctx.CLAMP_TO_EDGE);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MIN_FILTER, picSizeMode);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MAG_FILTER, picSizeMode);
            return text
        })

        this.gl.textureWireCache = Array.from({ length: MAX_ARRAY_TEXTURE_CACHE }).map(e => {
            const text = this.ctx.createTexture() as WebGLTexture
            this.ctx.bindTexture(this.ctx.TEXTURE_2D, text);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_S, this.ctx.CLAMP_TO_EDGE);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_T, this.ctx.CLAMP_TO_EDGE);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MIN_FILTER, wireSizeMode);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MAG_FILTER, wireSizeMode);
            return text
        })

        this.gl.textureMapCache = Array.from({ length: MAX_ARRAY_TEXTURE_CACHE }).map(e => {
            const text = this.ctx.createTexture() as WebGLTexture
            this.ctx.bindTexture(this.ctx.TEXTURE_2D, text);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_S, this.ctx.CLAMP_TO_EDGE);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_WRAP_T, this.ctx.CLAMP_TO_EDGE);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MIN_FILTER, mapSizeMode);
            this.ctx.texParameteri(this.ctx.TEXTURE_2D, this.ctx.TEXTURE_MAG_FILTER, mapSizeMode);
            return text
        })



        this.resetImageCache()
        this.gl.resolutionLocation = this.ctx.getUniformLocation(this.program, "u_resolution") as WebGLUniformLocation;

        // provide texture coordinates for the rectanthis.ctxe.
        var texcoordBuffer = this.ctx.createBuffer();
        this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, texcoordBuffer);
        this.ctx.bufferData(this.ctx.ARRAY_BUFFER, new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,]), this.ctx.STATIC_DRAW);
        this.ctx.enableVertexAttribArray(this.gl.texcoordLocation);
        // bind the texcoord buffer.
        this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, texcoordBuffer);
        // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
        this.ctx.vertexAttribPointer(
            this.gl.texcoordLocation, 2, this.ctx.FLOAT, false, 0, 0);

        this.gl.colors = this.ctx.getUniformLocation(this.program, "u_wires") as WebGLUniformLocation;
        this.gl.colors_size = this.ctx.getUniformLocation(this.program, "u_wires_size") as WebGLUniformLocation;
        this.gl.width = this.ctx.getUniformLocation(this.program, "u_width") as WebGLUniformLocation;
        this.gl.height = this.ctx.getUniformLocation(this.program, "u_height") as WebGLUniformLocation;
        this.gl.wireOffset = this.ctx.getUniformLocation(this.program, "u_wireOffset") as WebGLUniformLocation;
        this.gl.isMap = this.ctx.getUniformLocation(this.program, "u_isMap") as WebGLUniformLocation;

        // this.ctx.enable(this.ctx.BLEND);
        // this.ctx.blendFunc(this.ctx.SRC_ALPHA, this.ctx.ZERO);
        // this.ctx.disable(this.ctx.DEPTH_TEST);
    }

    public preloadImage(image: HTMLImageElement, wire: boolean, map: boolean) {
        if (wire) {
            const slot = this.gl.imageTexureWireCache.findIndex(e => e === null)
            if (slot !== -1) {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.textureWireCache[slot]);
                this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGBA, this.ctx.RGBA, this.ctx.UNSIGNED_BYTE, image);
                this.gl.imageTexureWireCache[slot] = image;
            }
        } else if (map) {
            const slot = this.gl.imageTexureMapCache.findIndex(e => e === null)
            if (slot !== -1) {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.textureMapCache[slot]);
                this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGBA, this.ctx.RGBA, this.ctx.UNSIGNED_BYTE, image);
                this.gl.imageTexureMapCache[slot] = image;
            }
        } else {
            const slot = this.gl.imageTexurePicCache.findIndex(e => e === null)
            if (slot !== -1) {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.texturePicCache[slot]);
                this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGB, this.ctx.RGB, this.ctx.UNSIGNED_BYTE, image);
                this.gl.imageTexurePicCache[slot] = image;
            }

        }
        if (this.gl.lastTexturePainted) {
            this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.lastTexturePainted);
        }
        return;
    }

    public setWireOffset(offset: number) {
        this.wireOffset = offset;
        this.ctx.uniform1i(this.gl.wireOffset, this.wireOffset)
    }

    public paint(pic: HTMLImageElement, wire?: HTMLImageElement, map?: HTMLImageElement) {
        this.ctx.uniform1i(this.gl.width, this.glCanvas.width)
        this.ctx.uniform1i(this.gl.height, this.glCanvas.height)
        this.ctx.uniform1i(this.gl.isMap, map ? 1 : 0)


        this.ctx.activeTexture(this.ctx.TEXTURE0);
        const slot = this.gl.imageTexurePicCache.findIndex(e => e === pic)

        if (slot !== -1) {
            this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.texturePicCache[slot]);
            this.gl.lastTexturePainted = this.gl.texturePicCache[slot];
        } else {
            this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.imageTexture);
            this.gl.lastTexturePainted = this.gl.imageTexture;
            this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGB, this.ctx.RGB, this.ctx.UNSIGNED_BYTE, pic);
        }

        // this.ctx.activeTexture(this.ctx.TEXTURE0);
        // this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.imageTexture);
        // this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGB, this.ctx.RGB, this.ctx.UNSIGNED_BYTE, pic);

        if (wire && wire !== this.gl.lastImageWirePainted) {
            this.ctx.activeTexture(this.ctx.TEXTURE0 + 1);
            const slot = this.gl.imageTexureWireCache.findIndex(e => e === wire)
            if (slot !== -1) {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.textureWireCache[slot]);
                this.gl.lastTexturePainted = this.gl.textureWireCache[slot];
            } else {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.wireTexture);
                this.gl.lastTexturePainted = this.gl.wireTexture;
                this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGBA, this.ctx.RGBA, this.ctx.UNSIGNED_BYTE, wire);
            }
            this.gl.lastImageWirePainted = wire

            // this.ctx.activeTexture(this.ctx.TEXTURE0 + 1);
            // this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.wireTexture);
            // this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.LUMINANCE, this.ctx.LUMINANCE, this.ctx.UNSIGNED_BYTE, wire);
        }

        if (map && map !== this.gl.lastImageMapPainted) {
            this.ctx.activeTexture(this.ctx.TEXTURE0 + 2);
            const slot = this.gl.imageTexureMapCache.findIndex(e => e === map)
            if (slot !== -1) {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.textureMapCache[slot]);
                this.gl.lastTexturePainted = this.gl.textureMapCache[slot];
            } else {
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.mapTexture);
                this.gl.lastTexturePainted = this.gl.mapTexture;
                this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.RGBA, this.ctx.RGBA, this.ctx.UNSIGNED_BYTE, map);
            }
            this.gl.lastImageMapPainted = map

            // this.ctx.activeTexture(this.ctx.TEXTURE0 + 1);
            // this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.gl.wireTexture);
            // this.ctx.texImage2D(this.ctx.TEXTURE_2D, 0, this.ctx.LUMINANCE, this.ctx.LUMINANCE, this.ctx.UNSIGNED_BYTE, wire);
        }


        this.ctx.drawArrays(this.ctx.TRIANGLES, 0, 6);
    }
}