export default class {
    images: { [key: string]: HTMLImageElement } = {}
    preloadQueueIndex = 0;
    queueIndex = 0;
    loadFastIndex = 0;
    constructor() {

    }

    public async loadImagesFast(paths: string[], callBack: (p: { image: HTMLImageElement, path: string, loading: number[] }) => any) {
        let totalLoaded = 0
        return Promise.all(paths.map(path => {
            return new Promise(resolve => {
                const img = new Image()
                img.src = path
                img.onload = () => {
                    this.images[path] = img
                    totalLoaded += 1;
                    callBack({
                        image: img,
                        path,
                        loading: [totalLoaded, paths.length]
                    })
                    resolve(img)
                }
            })
        }))
    }

    public async loadImages(paths: string[]) {
        this.queueIndex += 1;
        const actualQueue = this.queueIndex
        const n = 4
        const tmp = (JSON.parse(JSON.stringify(paths)) as string[]).filter(e => !this.images[e])
        const threads = new Array(Math.ceil(tmp.length / n))
            .fill(undefined)
            .map(_ => tmp.splice(0, n))
        for (let thread of threads) {
            if (this.queueIndex !== actualQueue) {
                return false;
            }
            await Promise.all(thread.map(path => {
                return this.loadImage(path)
            }))
        }
        return paths.map(path => {
            return this.images[path]
        });
    }

    public async loadImage(path: string): Promise<HTMLImageElement> {
        if (this.images[path]) {
            return this.images[path]
        }
        return new Promise(resolve => {
            const img = new Image()
            img.src = path;
            img.onload = () => {
                this.images[path] = img
                resolve(img)
            }
        })
    }
}